<template>
  <div class="flex flex-col">
    <span
      class="-mt-10 font-extrabold text-blue-500
      items-center inline-flex cursor-pointer"
      @click="$emit('goBack', 'confirm-branch')"
    >
      <img :src="assets.icons.sm.back" class="mr-2" alt="Back" />
      Back
    </span>
    <h1 class="text-34 mt-2 font-black tracking-tight mb-2">
      {{ businessName | sentenceCase }}
    </h1>
    <p class="text-12 font-normal mb-4 text-marketplace-blue text-opacity-75">
      Choose a way to pay.
    </p>

    <div
      v-for="(m, i) in methods"
      :key="i"
      class="border-0.7 rounded w-full min-h-16 bg-marketplace-lightgray
      cursor-pointer flex justify-between items-center p-5 mt-4"
      :class="method === m.type ? 'border-blue-500' : 'border-marketplace-gray'"
      @click="setMethod(m.type)"
      :ref="m.type"
    >
      <div class="inline-flex items-center">
        <div
          class="rounded-full w-6 h-6 flex justify-center items-center"
          :class="method === m.type ? 'bg-blue-500' : 'border-0.7 bg-white'"
        >
          <ion-icon
            v-if="method === m.type"
            name="checkmark-outline"
            class="text-white"
          ></ion-icon>
        </div>
        <div class="flex flex-col ml-4">
          <h2 class="text-marketplace-blue font-bold text-13 tracking-tight">
            {{ m.title }}
          </h2>
          <p
            class="mt-1 text-marketplace-blue text-opacity-75 text-10 font-normal"
          >
            {{ m.type === 'credit-card' ? `Repay by ${billingDate}` : m.info }}
          </p>
        </div>
      </div>
      <div
        class="rounded-full text-8 text-white bg-marketplace-green
        w-17 p-2 flex justify-center items-center font-bold"
        v-if="m.type === 'credit-card' && !!m.discount"
      >
        Get {{ m.discount }}% off
      </div>
    </div>

    <div class="mt-32 flex justify-center">
      <button
        class="btn btn-blue py-6 w-full"
        type="button"
        :disabled="!method"
        @click="proceed"
      >
        Continue
      </button>
    </div>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  data() {
    return {
      method: null,
      paymentMethodResource: this.$options.resource([])
    };
  },
  computed: {
    businessName() {
      return this.$attrs?.vendor?.business_name;
    },
    merchantId() {
      return this.$attrs?.vendor?.id;
    },
    inputedAmount() {
      return this.$attrs?.order?.amount?.value;
    },
    billingDate() {
      return this.$attrs?.billing_date;
    },
    methods() {
      return this.paymentMethodResource.data;
    }
  },
  watch: {
    'paymentMethodResource.data': {
      handler: function() {
        this.paymentMethodResource.data[0]?.type === 'credit-card'
          ? (this.method = 'credit-card')
          : (this.method = 'loan-limit');
      },
      deep: true
    }
  },
  beforeMount() {
    this.getPaymentMethod();
  },
  methods: {
    setMethod(method) {
      this.method = method;
    },
    async getPaymentMethod() {
      await this.sendRequest(
        'personal.marketplace.paymentSummary.paymentMethod',
        this.inputedAmount,
        this.merchantId,
        {
          success: response => {
            this.paymentMethodResource.data = response.data.data;
          },
          error: error => console.log(error)
        }
      );
    },
    proceed() {
      this.$emit('setPaymentMethod', this.method);
      this.$emit('proceed', 'method-information');
    }
  }
};
</script>
